// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "eu-west-2",
  "aws_cognito_identity_pool_id": "eu-west-2:a9ac18d5-9b41-49c4-b3cd-e8814da28dd0",
  "aws_cognito_region": "eu-west-2",
  //"aws_user_pools_id": "us-east-1_qhDK6Wqby",
  //"aws_user_pools_web_client_id": "6vi4uqc6v6qjcp3n37msji3tpm",
  "aws_user_pools_id": "eu-west-2_wSsckF7Wi",
  "aws_user_pools_web_client_id": "5e8bd5qr2au0vfif7kk04vh48p",
  "oauth": {
    "domain": "dxc-uk00-prd0.auth.eu-west-2.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://intelli-search.uk.platform.dxc.com",
    "redirectSignOut": "https://intelli-search.uk.platform.dxc.com",
    // "redirectSignIn": "http://localhost:4200/",
    // "redirectSignOut": "http://localhost:4200/",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://wdl5dqjgqzbpbengqo7nr3lg6u.appsync-api.eu-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};
export default awsmobile;